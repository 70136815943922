import { ReactNode } from "react";
import styled, { css } from "styled-components";

import { transition } from "../../common/mixins";
import { media } from "./../../common/MediaQueries";
import { Link } from "gatsby";

interface Props {
  href?: string;
  target?: string;
  rel?: string;
  to?: string;
  as?: string;
  internal?: string;
  big?: string;
  alt?: string;
  contact?: boolean;
  small?: boolean;
  medium?: boolean;
  isBold?: boolean;
  fontSize?: number;
  autoWidth?: boolean;
  blue?: boolean;
  pink?: boolean;
  watermelon?: boolean;
}

const ButtonLinkStyled = styled(Link)<Props>`
  ${transition}
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  width: auto;
  min-width: ${({ theme }) => theme.btn.width};
  justify-content: center;
  cursor: pointer;

  /* Contact */
  ${({ contact }) =>
    contact &&
    css`
      background-color: ${({ theme }) => theme.colors.white};
      border: 1px solid ${({ theme }) => theme.colors.dark};
      border-radius: 0.9375rem;
      color: ${({ theme }) => theme.colors.dark};
      font-size: ${({ theme }) => theme.fontSize.fontSize12};
      font-weight: ${({ theme }) => theme.fontWeight.regular};
      padding: 0 1rem;

      &:hover {
        background-color: ${({ theme }) => theme.colors.dark};
        color: ${({ theme }) => theme.colors.white};
      }
      @media ${media.phone} {
        font-size: ${({ theme }) => theme.fontSize.fontSize14};
        font-weight: ${({ theme }) => theme.fontWeight.semiBold};
        padding: 0 1.125rem;
      }
    `}

  ${({ autoWidth }) =>
    autoWidth &&
    css`
      min-width: 0;
    `}

  /* Internal */
 ${({ internal }) =>
    internal &&
    css`
      box-shadow: 0 10px 20px -10px rgba(0, 0, 0, 0.5);
      border-radius: 30px;
      font-weight: ${({ theme }) => theme.fontWeight.semiBold};
      font-size: 12px;
      color: ${({ theme }) => theme.colors.white};
      letter-spacing: -0.38px;
      text-align: center;
      text-transform: uppercase;
      min-width: 180px;
      height: 40px;
      padding: 5px 10px;

      &:hover {
        opacity: 0.85;
      }
    `}

 /* Blue */
  ${({ blue }) =>
    blue &&
    css`
      background: ${({ theme }) => theme.colors.main};
    `}

 /* Pink */
  ${({ pink }) =>
    pink &&
    css`
      background-image: linear-gradient(-45deg, #ff88a9 0%, #ff5070 100%);
    `}

  /* Watermelon */
  ${({ watermelon }) =>
    watermelon &&
    css`
      background-color: ${({ theme }) => theme.colors.watermelon};
      &:hover {
        background-color: ${({ theme }) => theme.colors.blue};
        opacity: 1;
      }
    `}
  
  /* Small */
  ${({ small }) =>
    small &&
    css`
      height: ${({ theme }) => theme.btn.heightSmall};
    `}
    
  /* Medium */
  ${({ medium }) =>
    medium &&
    css`
      height: 45px;
      border-radius: 5px;
      min-width: 160px;
    `}

  /* Big */
  ${({ big }) =>
    big &&
    css`
      height: 40px;
      min-width: 160px;
      border-radius: 5px;
      @media ${media.phone} {
        min-width: 320px;
        height: 66px;
      }
    `}

  /* Alt */
  ${({ alt }) =>
    alt &&
    css`
      height: 42px;
      font-size: 16px;
      font-weight: ${({ theme }) => theme.fontWeight.semiBold};
      border-radius: 22.5px;
      @media ${media.phone} {
        font-size: 21px;
        min-width: 330px;
      }
    `}

    /* isBold */
  ${({ isBold }) =>
    isBold &&
    css`
      font-weight: ${({ theme }) => theme.fontWeight.bold};
      font-size: 16px;
      min-width: 210px;
      height: 50px;
    `}

  ${({ fontSize }) =>
    fontSize &&
    css`
      && {
        font-size: 16px;
        line-height: 22px;
      }
    `}
`;

export default ButtonLinkStyled;
