import styled from "styled-components";
import { transition } from "../../common/mixins";

const SocialMediaLinkStyled = styled.a`
  display: flex;
  align-items: center;
  flex-shrink: 0;
  color: ${({ theme }) => theme.colors.dark};
  ${transition}
  padding: 10px;

  &:hover {
    color: ${({ theme }) => theme.colors.watermelon};
  }
`;

const SocialMediaListStyled = styled.ul`
  display: flex;
`;

const SocialMediaItemStyled = styled.li`
  &:not(:last-child) {
    margin-right: 8px;
  }
`;

const SocialMediaIconStyled = styled.span`
  display: flex;
  font-size: 18px;

  svg {
    fill: currentColor;
  }
`;

export {
  SocialMediaListStyled,
  SocialMediaItemStyled,
  SocialMediaLinkStyled,
  SocialMediaIconStyled,
};
