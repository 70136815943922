import styled, { css } from "styled-components";

import { media } from "./../common/MediaQueries";

interface IProps {
  isFullHeight?: boolean;
}

const Main = styled.main<IProps>`
    padding-top: 53px;

    ${({ isFullHeight }) =>
      isFullHeight &&
      css`
        padding-top: 0;
      `}

    @media ${media.tablet} {
      padding-top: 70px;

      ${({ isFullHeight }) =>
        isFullHeight &&
        css`
          padding-top: 0;
        `}
    }
  }
`;

export default Main;
