import { Link } from "gatsby";
import React, { useCallback } from "react";
import { IUrlItem } from "../../interfaces/IUrlItem";

import { MainNavLinkStyled } from "./styles";

interface Props { 
  item: IUrlItem
  active: boolean
  childActive: boolean
}

const MainNavItem: React.FC<Props> = ({ active, childActive, item }) => {
  const { title, url, } = item;

  if(url.indexOf('https://') === -1) {
    <Link to={url}>
      <MainNavLinkStyled
        as="span"
        $childActive={childActive}
        $active={active}
      >
        {title}
      </MainNavLinkStyled>
    </Link>
  }

  return (
    <MainNavLinkStyled 
      href={url}
      $childActive={childActive}
      $active={active}
    >
      {title}
    </MainNavLinkStyled>
  );
};

export default MainNavItem;
