import React from "react";

import ButtonLinkStyled from "./styles";

interface Props {
  href?: string;
  target?: string;
  rel?: string;
  to?: string;
  as?: string;
  internal?: boolean;
  pink?: boolean
  big?: boolean;
  alt?: string;
  contact?: boolean;
  small?: boolean;
  autoWidth?: boolean;
  fontSize?: number;
  watermelon?: boolean
}

const ButtonLink: React.FC<Props> = ({ children, ...rest }) => (
  <ButtonLinkStyled {...rest}>{children}</ButtonLinkStyled>
);

export default ButtonLink;
