import styled, { css } from "styled-components";
import { Link } from "gatsby";
import { media } from "./../../common/MediaQueries";

interface MainNavLinkStyledProps { 
  $active: boolean
  $childActive: boolean
}

const MainNavLinkStyled = styled.a<MainNavLinkStyledProps>`
  display: block;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 18px;
  line-height: 28px;
  font-weight: ${({ theme }) => theme.fontWeight.light};
  white-space: no-wrap;
  background: white;
  position: relative;

  &:hover {
    color: ${({ theme }) => theme.colors.watermelon};
  }

  ${({ $active }) => $active 
    ? css`
      font-weight: 700; 
      color: ${({ theme }) => theme.colors.watermelon};
    `
    : ''
  }

  @media ${media.tablet} {
    font-size: 14px;
    line-height: 20px;

    ${({ $childActive }) => $childActive 
      ? css`
        font-weight: 700; 
        color: ${({ theme }) => theme.colors.watermelon};
      `
      : ''
    }
  }

  &.active {
    color: ${({ theme }) => theme.colors.watermelon};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

export { MainNavLinkStyled };
