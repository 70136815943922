import theme from "../themes/theme";

const [phone, tablet, desktop] = theme.breakpoints;

export const media = {
  phone: `(min-width: ${phone})`,
  tablet: `(min-width: ${tablet})`,
  desktop: `(min-width: ${desktop})`,
};

const [maxPhone, maxTablet, maxDesktop] = theme.maxBreakpoints;

export const customMedia = {
  inner: `(min-width: ${theme.sizes.inner})`,
  maxPhone: `(max-width: ${maxPhone})`,
  maxTablet: `(max-width: ${maxTablet})`,
  maxDesktop: `(max-width: ${maxDesktop})`,
};
