import styled, { css } from "styled-components";
import { media } from "../../common/MediaQueries";

export const SubMenuWrapper = styled.div`
  padding-left: 25px;

  @media ${media.tablet} {
    position: absolute;
    left: 0; 
    top: 100%;
    min-width: 100%;
    max-width: none;
    padding: 8px 20px;
    background: white;
    box-shadow: 0 4px 6px -1px rgb(0 0 0 / 0.1), 0 2px 4px -2px rgb(0 0 0 / 0.1);
    transform: translateY(-100%); 
    opacity: 0; 
    pointer-events: none;
    transition: transform 200ms ease-out, opacity 200ms ease-out;
  }
`

interface SubMenuLinkProps { 
  active: boolean
}

export const SubMenuLink = styled.a<SubMenuLinkProps>`
  display: block;
  font-size: 18px;
  font-weight: 300;
  margin-top: 12px;

  ${({ active }) => active 
    ? css`
        font-weight: 700;
        color: ${({ theme }) => theme.colors.watermelon};    
      `
    : '' 
  }
  
  &:hover { 
    color: ${({ theme }) => theme.colors.watermelon};
  }

  @media ${media.tablet} {
    font-size: 14px;
    margin-top: 12px;
    margin-bottom: 8px; 
  }
`