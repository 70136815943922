import React from "react";
import { Link } from "gatsby";

import {
  FooterWrapperStyled,
  FooterStyled,
  FooterItemStyled,
  FooterItemLogoStyled,
  FooterItemDescriptionStyled,
  FooterItemActionStyled,
  FooterItemSocialsStyled,
  FooterLink,
} from "./styles";
import Inner from "./../Inner";

import Logo from "./../Logo";
import SocialMedia from "./../SocialMedia";
import Copyrights from "./../Copyrights";
import ButtonLink from "./../ButtonLink/index";

import { socialLinks } from "./../../mocks/socialLinks";

interface Props {
  skipBorder?: boolean;
  borderOnMobile?: boolean;
  mobileMargin?: number;
}

const Footer: React.FC<Props> = ({
  skipBorder,
  borderOnMobile,
  mobileMargin,
}) => {
  const copyRightText = (
    <>
      <strong>Remote Work.</strong> Get the best talent out there - anywhere!
    </>
  );

  return (
    <FooterWrapperStyled>
      <Inner>
        <FooterStyled
          skipBorder={skipBorder}
          borderOnMobile={borderOnMobile}
          mobileMargin={mobileMargin}
        >
          <FooterItemLogoStyled>
            <Logo href="/" big={true} />
          </FooterItemLogoStyled>
          <FooterItemStyled grow={true}>
            <FooterItemActionStyled tight>
              <h2>Quick links</h2>
            </FooterItemActionStyled>
            <FooterItemActionStyled tight>
              <FooterLink href="/cookies/">Privacy Policy</FooterLink>
            </FooterItemActionStyled>
            <FooterItemActionStyled>
              <FooterLink href="/terms/">Terms and Conditions</FooterLink>
            </FooterItemActionStyled>
          </FooterItemStyled>
          <FooterItemStyled alignRight={true}>
            <FooterItemDescriptionStyled>
              <Copyrights text={copyRightText} />
              <FooterItemActionStyled alignRight={true}>
                <ButtonLink
                  as="a"
                  contact
                  small
                  href="mailto:bestmatch@flatworld.co"
                  fontSize={16}
                >
                  bestmatch@flatworld.co
                </ButtonLink>
              </FooterItemActionStyled>
            </FooterItemDescriptionStyled>
            <FooterItemSocialsStyled>
              <SocialMedia items={socialLinks} />
            </FooterItemSocialsStyled>
          </FooterItemStyled>
        </FooterStyled>
      </Inner>
    </FooterWrapperStyled>
  );
};

export default Footer;
