import React, { useState, useEffect } from "react";
import { globalHistory } from "@reach/router";

import {
  HeaderWrapperStyled,
  HeaderStyled,
  HeaderLeftStyled,
  HeaderRightStyled,
  HeaderLogoStyled,
  HeaderNavigationWrapperStyled,
  HeaderNavigationStyled,
  HeaderNavigationStyledMenu,
  HeaderNavigationStyledSocial,
  HeaderNavigationWrapStyled,
  HeaderNavigationStyledAction,
} from "./styles";
import Inner from "./../Inner";
import Logo from "./../Logo";
import MainNav from "./../MainNav";
import SocialMedia from "./../SocialMedia";
import Burger from "./../Burger";
import ButtonLink from "./../ButtonLink/index";
import { navigationMenu } from "./../../mocks/navigationMenu";
import { socialLinks } from "./../../mocks/socialLinks";
import useUpdate from "../../hooks/useUpdate";
import useWindowSize from "../../hooks/windowSize";

interface IProps {
  location: string;
}

const TABLET_BREAKPOINT = 1025;

const Header: React.FC<IProps> = ({ location }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const [pageScrolled, setPageScrolled] = useState(false);

  const { width: windowWidth, height: windowHeight } = useWindowSize();

  function toggleMenuHandler() {
    setMenuOpen(!menuOpen);
  }

  function changeBackground() {
    if (!document.scrollingElement) return;

    if (document.scrollingElement.scrollTop >= 110) {
      setPageScrolled(true);
    } else {
      setPageScrolled(false);
    }
  }

  useUpdate(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }

    return () => {
      document.body.style.overflow = "";
    };
  }, [menuOpen]);

  useUpdate(() => {
    if (windowWidth <= TABLET_BREAKPOINT) {
      const root = document.querySelector(":root") as HTMLElement;

      root.style.setProperty("--vh", window.innerHeight / 100 + "px");
    } else {
      document.body.style.overflow = "";
    }
  }, [windowWidth, windowHeight]);

  useEffect(() => {
    window.addEventListener("scroll", changeBackground, { passive: true });

    return () => window.removeEventListener("scroll", changeBackground);
  }, []);

  return (
    <HeaderWrapperStyled isPageScrolled={pageScrolled ? true : false}>
      <Inner>
        <HeaderStyled>
          <HeaderLeftStyled>
            <HeaderLogoStyled>
              <Logo
                href="/"
              />
            </HeaderLogoStyled>
          </HeaderLeftStyled>
          <HeaderRightStyled>
            <HeaderNavigationWrapperStyled>
              <HeaderNavigationStyled isOpened={menuOpen}>
                <HeaderNavigationWrapStyled>
                  <HeaderNavigationStyledMenu>
                    <MainNav
                      list={navigationMenu}
                      location={location}
                    />
                  </HeaderNavigationStyledMenu>
                  <HeaderNavigationStyledSocial>
                    <SocialMedia items={socialLinks} />
                  </HeaderNavigationStyledSocial>
                  <HeaderNavigationStyledAction>
                    <ButtonLink
                      as="a"
                      contact
                      small
                      href="mailto:bestmatch@flatworld.co"
                    >
                      bestmatch@flatworld.co
                    </ButtonLink>
                  </HeaderNavigationStyledAction>
                </HeaderNavigationWrapStyled>
              </HeaderNavigationStyled>
              <Burger isOpened={menuOpen} clickHandler={toggleMenuHandler} />
            </HeaderNavigationWrapperStyled>
          </HeaderRightStyled>
        </HeaderStyled>
      </Inner>
    </HeaderWrapperStyled>
  );
};

export default Header;
