import React from "react";
import PropTypes from "prop-types";
import Helmet from "react-helmet";
import { useStaticQuery, graphql } from "gatsby";
import { propTypes } from "gatsby-plugin-image/dist/src/components/static-image.server";

interface IProps {
  title: string;
  url: string;
  description?: string;
  ogDescription?: string;
  image?: string;
  twitterImage?: string;
  meta?: [{ name: string; content: string }] | [];
  lang?: string;
  schemaMarkup?: object;
}

const SEO: React.FC<IProps> = ({
  description,
  ogDescription,
  lang,
  meta,
  title,
  url: path,
  image,
  twitterImage,
  schemaMarkup,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            author
          }
        }
      }
    `
  );

  const url = `https://${process.env.GATSBY_PUBLIC_DOMAIN}${path}`;

  const metaDescription = description || site.siteMetadata.description;
  const metaOgDescription =
    ogDescription || description || site.siteMetadata.description;

  return (
    <Helmet
      htmlAttributes={{
        lang,
      }}
      title={title}
      titleTemplate={`%s`}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaOgDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          property: `og:url`,
          content: url,
        },
        {
          property: `og:image`,
          content: image || twitterImage,
        },
        {
          property: `twitter:title`,
          content: title,
        },
        {
          property: `twitter:url`,
          content: url,
        },
        {
          property: `twitter:image`,
          content: twitterImage || image,
        },
        {
          property: `fb:app_id`,
          content: process.env.GATSBY_FB || "",
        },
        {
          property: 'canonical',
          content: url,
        }
      ].concat(meta || [])}
    >
      {schemaMarkup && (
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      )}
    </Helmet>
  );
};

SEO.defaultProps = {
  lang: `en`,
  meta: [],
  description: ``,
};

// SEO.propTypes = {
//   description: PropTypes.string,
//   lang: PropTypes.string,
//   meta: PropTypes.array,
//   title: PropTypes.string.isRequired,
// }

export default SEO;
