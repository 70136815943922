import { IUrlItem } from "./../interfaces/IUrlItem";

export const navigationMenu: IUrlItem[] = [
  {
    id: 'home',
    title: "Home",
    url: "/",
  },
  {
    id: 'companies',
    title: "For Companies",
    url: `https://${process.env.GATSBY_PUBLIC_DOMAIN}/companies`,
    subitems: [
      {
        id: 'plans',
        title: 'Get Started',
        url: `https://${process.env.GATSBY_PUBLIC_DOMAIN}/companies`
      },
      {
        id: 'plans',
        title: 'Plans',
        url: `https://${process.env.GATSBY_PUBLIC_DOMAIN}/plans`
      },
    ]
  },
  {
    id: 'candidates',
    title: "For Candidates",
    url: `/get-started/`,
    subitems: [
      {
        id: 'get-started',
        title: "Get Started",
        url: "/get-started/",
      },
      {
        id: 'success',
        title: "Success Stories",
        url: "/success-stories/",
      },
    ]
  },
  {
    id: 'jobs',
    title: "Jobs",
    url: "/jobs/",
  },
  {
    id: 'about',
    title: "About Us",
    url: "/about-us/",
  },
  {
    id: 'blog',
    title: "Blog",
    url: "/blogs/",
  },
];
