import styled, { css } from "styled-components";
import { Link } from "gatsby";

import { media, customMedia } from "../../common/MediaQueries";
import { SubMenuWrapper } from "../SubMenu/styles";

const MainNavListStyled = styled.ul`
  display: flex;
  width: 100%;
  padding: 0 25px;
  align-items: flex-start;

  @media ${customMedia.maxTablet} {
    flex-direction: column;
  }

  @media ${media.tablet} {
    padding: 0;
  }
`;

export const HeaderArrow = styled.div`
  display: none; 

  @media ${media.tablet} {
    display: block;
    position: relative;
    margin-left: 8px;
    width: 12px;
    height: 12px;
    transition: transform 200ms ease-out;
    transform-origin: center;
  }

  & svg { 
    width: 100%; 
    height: 100%;
    display: block;
  }
`

export const SubitemsLine = styled.div`
  display: none;

  @media ${media.tablet} { 
    display: block; 
    opacity: 0;
    position: absolute; 
    bottom: 0; 
    left: 0;
    width: calc(100% - 20px); 
    height: 2px;
    background: ${({ theme }) => theme.colors.watermelon};
    transition: opacity 200ms ease-out;
  }
`

interface IMainNavItemStyled {
  hideOnDesktop?: boolean;
  hasSubitems?: boolean;
}

const MainNavItemStyled = styled.li<IMainNavItemStyled>`
  display: flex; 
  flex-direction: column-reverse;
  white-space: nowrap;
  margin-bottom: 12px; 
  padding-bottom: 12px;
  border-bottom: 1px solid #dedede;
  width: 100%;
  text-align: left;
  position: relative;

  @media ${media.tablet} {
    flex-direction: row;
    align-items: center;
    border-bottom: none;
    margin-bottom: 0;
    padding-bottom: 24px;
    padding-top: 26px;
    

    ${({ hasSubitems }) => hasSubitems
      ? 'padding-right: 20px;'
      : 'margin-right: 30px;'
    }

    ${({ hideOnDesktop }) => hideOnDesktop && "display:none;"}

    &&&&:hover {
      ${SubitemsLine} { 
        opacity: 1;
      }

      ${HeaderArrow} { 
        transform: rotate(180deg);
      }

      ${SubMenuWrapper} { 
        transform: translateY(0);
        opacity: 1;  
        pointer-events: auto;
      }
    }

    @media ${media.desktop} {
      ${({ hasSubitems }) => hasSubitems
      ? 'padding-right: 30px;'
      : 'margin-right: 40px;'
    }
    }
  }
`;

const MainNavLinkStyled = styled(Link)`
  display: block;
  color: ${({ theme }) => theme.colors.dark};
  font-size: 18px;
  font-weight: ${({ theme }) => theme.fontWeight.light};

  &:hover {
    color: ${({ theme }) => theme.colors.watermelon};
  }

  @media ${media.tablet} {
    font-size: 14px;
  }

  &.active {
    color: ${({ theme }) => theme.colors.watermelon};
    font-weight: ${({ theme }) => theme.fontWeight.bold};
  }
`;

const MainNavStyled = styled.div`
  display: flex;
  align-items: center;
`;

export const ItemBackground = styled.div`
  display: none; 

  @media ${media.tablet} { 
    display: block; 
    position: absolute;
    top: 0; 
    left: 0;
    width: 100%; 
    height: 100%;
    background: white;
  }
`

export {
  MainNavStyled,
  MainNavListStyled,
  MainNavItemStyled,
  MainNavLinkStyled,
};
