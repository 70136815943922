import styled from "styled-components";
import {
  lineHeight,
  fontSize,
  color,
  textStyle,
  fontWeight,
  textAlign,
  fontFamily,
} from "styled-system";

export interface ITypoStyles {
  fontSize?: string[];
  fontWeight?: string;
  lineHeight?: string;
  letterSpacing?: string;
  textAlign?: string;
}

type IProps = ITypoStyles;

const StyledDynamicComponent = styled.p<IProps>`
  ${lineHeight}
  ${fontSize}
  ${color}
  ${textStyle}
  ${fontWeight}
  ${textAlign}
  ${fontFamily}
`;

export default StyledDynamicComponent;
